.menuContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    height: 24px;
}

.menu {
    display: flex;
    justify-content: flex-end;
    width: 110px;
    color: var(--text);
    font-family: 'Cormorant Garamond', serif;
}

.nav ul {
    list-style: none;
    padding: 0;
    flex-direction: column;
    transition: max-height 0.2s ease-out;
    max-height: 0;
    position: absolute;
    top: 33px;
    right: 15px;
    border-radius: 0.6em 0 0.6em 0.6em;
    background: var(--theme);
    font-weight: 750;
    overflow: hidden;
}

.nav li {
    cursor: pointer;
    padding: 1px 6px;
    margin: 0 6px 2px 6px;
    transition: 0.2s;
    text-transform: uppercase;
}

.nav ul div:last-child li{margin: 0 6px}

.active{
    display: block;
    background: var(--blue) !important;
    color: var(--theme) !important;
}

nav:not(.active){color: var(--blue)}

.burger_container {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 999 !important;
}

.burger {
    position: relative;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    width: 32px;
    height: 28px;
    margin-right: 10px;
    background: var(--theme);
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    z-index: 999 !important;
}
.nav ul.menuOpen {
    max-height: 300px;
    transform-origin: right;
    animation: unfoldMenu 0.3s ease-out forwards;
    z-index: 999 !important;
}

.nav div:hover{
    background-color: var(--lightBlue) !important;
    color: var(--theme);
    transition: 80ms;
}

.nav ul.open{display: block;}


@keyframes unfoldMenu {
    from{transform: scaleX(0.25)}
    to{transform: scaleY(1)}
}


@media (min-width: 600px) {
    .menuContainer{justify-content: center}

    .nav ul.menuOpen{transform-origin:center}

    .menu {
        width: 100%;
        justify-content: center;
    }

    .nav ul {
        top: 3px;
        transform: translateX(-50%);
        border-radius: 0.3em;
        flex-direction: row;
        right:auto;
    }

    .burger{display: none}

    .nav ul.menuOpen{animation: none}

    .navList{
        display: flex;
        justify-content: flex-end;
        list-style: none;
        padding: 0;
    }
}
