.presentation{
    color: var(--text);
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 2% 0 2%;
    overflow: hidden;
}

.paragraphePresentation{
    width: 470px;
    text-align: left;
    padding: 10px 15px 0 15px;
}

.fred{
    color: var(--green);
    font-size: 1.15em;
}

.fredName{
    color: rgba(180, 180, 180, 0.8);
    font-size: 0.8em;
}

.xoxo{
    border-radius: 0 0 1em 1em;
    position: absolute;
    right: 6%;
    padding: 5px 8px;
    border: black 2px solid;
    border-top: none;
    color: var(--green);
}

.imageUp{
    margin-top: 40px;
    padding: 1%;
    border-radius: 2em;
}
.imageDown{
    padding: 1% 1% 0 1%;
    border-radius: 2em;
}

.nonSelectable{pointer-events: none}