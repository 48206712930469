.heartbeat {
    animation: heartbeat 1s linear 4s;
    animation-iteration-count: 7;
    opacity: 0.85;
    width: 50px;
    cursor: pointer;
}
@keyframes heartbeat {
    0% {transform: scale(1.1)}
    35% {transform: scale(1)}
    40% {transform: scale(1.15)}
    85% {transform: scale(1)}
}

.appearDiv{
    position: absolute;
    top: 2%;
    right: 2%;
    transform: scale(1);
    z-index: 10;
    animation: appear 5s linear;
    opacity: 0;
    animation-fill-mode: forwards;
}
@keyframes appear{
    from{opacity: 0}
    to{opacity: 1}
}