.reviews{
    margin-bottom: 57.5px;
    color: var(--text);
}

.formDiv{
    width: 300px;
    text-align: left;
    margin: auto;
}

.emailInput{
    width: 183px;
    background: var(--bgGrey);
    color: var(--text);
    border-right: none;
    cursor: pointer;
}

.email{
    background: var(--bgGrey); 
    border-left:none; 
    cursor: pointer;
    color: var(--text);
}

.retry{
    color: var(--text);
    background: var(--bgGrey)
}

.deleteBtn{
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 0.8em;
    color: var(--bgGrey);
    background-color: crimson !important;
    border-radius: 0.5em;
}

.deleteBtn:hover{
    color: var(--text);
    font-weight: 550;
    background-color: red;
    border: solid rgb(105, 5, 25) 2px
}

.editBtn{background-color: var(--blue) !important}
.editBtn:hover{font-weight: 550}